<!--
 * @Descripttion: 风格列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-15 16:16:34
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-23 10:19:38
-->
<template>
    <div class="scenariosList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">本地案例风格</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="sw-right">
                    <el-button class="btns" @click="isAddScenarios = true" type="primary">添加案例风格</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="name" label="风格名称" ></el-table-column>
                    <el-table-column align="center" fixed="right" label="操作" width="150">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :before-close="handleCloseScenarios" 
            :close-on-click-modal="false"
            :visible.sync="isAddScenarios" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">添加案例风格</div>
            <div v-else class="title">编辑案例风格</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="scenariosForm" :rules="rulesScenarios" ref="scenariosForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="案例风格" prop="name">
                        <el-input v-model="scenariosForm.name" placeholder="请输入风格名称"></el-input>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" :loading="btnLoading" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else :loading="btnLoading" type="primary" @click="submitFormEdit">编 辑</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data () {
        return {
            pageMixin_index:'scenariosStyle',
            keyword: '',
            currentPage: 1,
            total: 0,
            tableData: [],
            loading: false,
            addLoading: false,
            btnLoading: false,
            isAddScenarios: false,
            scenariosForm: {
                name: '',
            },
            rulesScenarios: {
                name: [
                    { required: true, message: '请输入风格名称', trigger: 'blur' },
                ],
            },
            image: [],
            editId: -1
        }
    },
    watch:{
      isAddScenarios(newVal,oldVal){
        if(!newVal){
          let scenariosForm = {
            building_id: '',
            room_id: '',
            name: '',
            link: '',
            use_good: [],
            cover: ''
          };
          this.editId = -1;
          this.scenariosForm = scenariosForm
        }
      }
    },
    mounted () {
        this.getSceneStyleList();
    },
    methods: {
        // 获取数据列表
        getSceneStyleList(){
            let params = {
                page: this.currentPage
            }
            this.loading = true;
            common.connect('/distributorpcv1/scene_case_style/index',params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 创建
        submitForm(){
            this.$refs.scenariosForm.validate((valid) => {
                if (valid) {
                    common.connect('/distributorpcv1/scene_case_style/store',this.scenariosForm,(res)=>{
                        if(res.isSuccess == 1){
                            this.getSceneStyleList();
                            this.isAddScenarios = false;
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑更新
        submitFormEdit(){
            this.$refs.scenariosForm.validate((valid) => {
                if (valid) {
                    this.scenariosForm.id = this.editId;
                    common.connect('/distributorpcv1/scene_case_style/update',this.scenariosForm,(res)=>{
                        if(res.isSuccess == 1){
                            this.getSceneStyleList();
                            this.isAddScenarios = false;
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            common.connect('/distributorpcv1/scene_case_style/edit',{id: row.id},(res)=>{
                this.isAddScenarios = true;
                this.scenariosForm.name = res.data.name;
            })
        },
        // 删除
        clickDelete(row){
            this.$confirm('此操作将永久删除该案例风格, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/distributorpcv1/scene_case_style/delete',{id: row.id},(res)=>{
                    this.getSceneStyleList();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 关键词搜索
        clickSearch(){
            this.getSceneStyleList();
        },
        // 关闭弹窗
        handleCloseScenarios(){
            this.isAddScenarios = false;
        },
        handleCurrentChange(){},
    },
}
</script>

<style lang='scss'>
.scenariosList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            .sw-left{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .screen-item{
                    margin-right: 15px;
                    margin-bottom: 20px;
                }
                .search-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 20px;
                    .el-input{
                        width: 220px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .table-wrap{
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 630px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
      
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .el-checkbox-group{
        .el-checkbox{
            margin-right: 0 !important;
        }
    }
}
</style>
